import { Box, Card, CardContent } from "@mui/material";
import { Formik, FormikValues } from "formik";
import * as React from 'react';
import * as Yup from "yup";
import researchConsumerType from "../../../../constants/researchConsumerType";
import researchProducerType from "../../../../constants/researchProducerType";
import RegistrationStepProps from "../props/RegistrationStepProps";
import RegistrationStepButtons from "../RegistrationStepButtons";
import RegistrationConsumerSection from "../section/RegistrationConsumerSection";
import RegistrationProducerSection from "../section/RegistrationProducerSection";
import RegistrationUserSection from "../section/RegistrationUserSection";

const RegistrationUserStep = ({handleUpdateData, userData, handleBack, activeStep, institutions}: RegistrationStepProps) => (
  <React.Fragment>
    <Formik
      initialValues={{
        name: userData ? userData.name : '',
        surname: userData ? userData.surname : '',
        username: userData ? userData.username : '',
        email: userData ? userData.email : '',
        type: userData ? userData.type : '',
        password: '',
        confirmPassword: '',
        producerName: userData && userData.producer ? userData.producer.name : '',
        producerVatNumber: userData && userData.producer ? userData.producer.vatNumber : '',
        producerAddress: userData && userData.producer ? userData.producer.address : '',
        producerCity: userData && userData.producer ? userData.producer.city : '',
        producerProvince: userData && userData.producer ? userData.producer.province : '',
        producerRegion: userData && userData.producer ? userData.producer.region : '',
        producerPostalCode: userData && userData.producer ? userData.producer.postalCode : '',
        producerInstitution: userData && userData.producer ? userData.producer.institution : '',
        producerDepartment: userData && userData.producer ? userData.producer.department : '',
        producerLab: userData && userData.producer ? userData.producer.parent : '',
        consumerName: userData && userData.consumer ? userData.consumer.name : '',
        consumerVatNumber: userData && userData.consumer ? userData.consumer.vatNumber : '',
        consumerAddress: userData && userData.consumer ? userData.consumer.address : '',
        consumerCity: userData && userData.consumer ? userData.consumer.city : '',
        consumerProvince: userData && userData.consumer ? userData.consumer.province : '',
        consumerRegion: userData && userData.consumer ? userData.consumer.region : '',
        consumerPostalCode: userData && userData.consumer ? userData.consumer.postalCode : '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
          .required('Il nome è obbligatorio e non può essere vuoto.'),
        surname: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
          .required('Il cognome è obbligatorio e non può essere vuoto.'),
        username: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
          .required('Lo username è obbligatorio e non può essere vuoto.'),
        email: Yup.string().max(255).email('Il valore inserito non è un formato corretto.')
          .required('L\'email è obbligatoria e non può essere vuota.'),
        type: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
          .required('La tipologia è obbligatoria e non può essere vuota.'),
        password: Yup.string().min(8, 'La password deve essere di almeno 8 caratteri')
          .max(255, 'Il valore massimo consentito è di 255 caratteri')
          // .matches(
          //   /^.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*$/,
          //   'E\' necessario almeno un carattere speciale',
          // )
          .required('La password è obbligatoria e non può essere vuota'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Le password devono corrispondere')
          .required('La conferma della password è obbligatoria e non può essere vuota.'),
        producerName: Yup.string().when('type', {
          is: (type: string) => {
            return researchProducerType.map(value => value.id).includes(type) && type !== 'RESEARCHER'
          },
          then: Yup.string().nullable().max(255, 'Il valore massimo consentito è di 255 caratteri')
            .required('Il nome è obbligatorio e non può essere vuoto.')
        }),
        producerVatNumber: Yup.string().when('type', {
          is: (type: string) => {
            return researchProducerType.map(value => value.id).includes(type) && (type === 'RESEARCH_CENTER' || type === 'SPINOFF')
          },
          then: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
              .matches(/^[0-9]{11}$/, 'Partita IVA non valida')
            .required('La partita IVA è obbligatoria e non può essere vuota.')
        }),
        producerAddress: Yup.string().when('type', {
          is: (type: string) => {
            return researchProducerType.map(value => value.id).includes(type)
          },
          then: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
            .required('L\'indirizzo è obbligatorio e non può essere vuota.')
        }),
        producerCity: Yup.string().when('type', {
          is: (type: string) => {
            return researchProducerType.map(value => value.id).includes(type)
          },
          then: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
            .required('La città è obbligatoria e non può essere vuota.')
        }),
        producerProvince: Yup.string().when('type', {
          is: (type: string) => {
            return researchProducerType.map(value => value.id).includes(type)
          },
          then: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
            .required('La provincia è obbligatoria e non può essere vuota.')
        }),
        producerRegion: Yup.string().when('type', {
          is: (type: string) => {
            return researchProducerType.map(value => value.id).includes(type)
          },
          then: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
            .required('La regione è obbligatoria e non può essere vuota.')
        }),
        producerPostalCode: Yup.string().when('type', {
          is: (type: string) => {
            return researchProducerType.map(value => value.id).includes(type)
          },
          then: Yup.string()
              .matches(/^[0-9]{5}$/, 'Il formato del codice postale non è valido.')
              .max(5, 'Il valore massimo consentito è di 5 caratteri')
            .required('Il codice postale è obbligatorio e non può essere vuota.')
        }),
        producerInstitution: Yup.string().when('type', {
          is: (type: string) => type === 'LABORATORY' || type === 'RESEARCHER',
          then: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
            .required('L\'istituto è obbligatorio e non può essere vuota.')
        }),
        producerDepartment: Yup.string().when(['type', 'producerInstitution'], {
          is: (type: string, producerInstitution: string) => type === 'LABORATORY' || (type === 'RESEARCHER' && institutions?.find(v => v.id === producerInstitution)?.type === 'UNIVERSITY'),
          then: Yup.string().nullable().max(255, 'Il valore massimo consentito è di 255 caratteri')
            .required('Il dipartimento è obbligatorio e non può essere vuota.')
        }),
        consumerName: Yup.string().when('type', {
          is: (type: string) => {
            return researchConsumerType.map(value => value.id).includes(type)
          },
          then: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
            .required('Il nome è obbligatorio e non può essere vuoto.')
        }),
        consumerVatNumber: Yup.string().when('type', {
          is: (type: string) => {
            return researchConsumerType.map(value => value.id).includes(type)
          },
          then: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
              .matches(/^[0-9]{11}$/, 'Partita IVA non valida')
            .required('La partita IVA è obbligatoria e non può essere vuota.')
        }),
        consumerAddress: Yup.string().when('type', {
          is: (type: string) => {
            return researchConsumerType.map(value => value.id).includes(type)
          },
          then: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
            .required('L\'indirizzo è obbligatorio e non può essere vuota.')
        }),
        consumerCity: Yup.string().when('type', {
          is: (type: string) => {
            return researchConsumerType.map(value => value.id).includes(type)
          },
          then: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
            .required('La città è obbligatoria e non può essere vuota.')
        }),
        consumerProvince: Yup.string().when('type', {
          is: (type: string) => {
            return researchConsumerType.map(value => value.id).includes(type)
          },
          then: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
            .required('La provincia è obbligatoria e non può essere vuota.')
        }),
        consumerRegion: Yup.string().when('type', {
          is: (type: string) => {
            return researchConsumerType.map(value => value.id).includes(type)
          },
          then: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
            .required('La regione è obbligatoria e non può essere vuota.')
        }),
        consumerPostalCode: Yup.string().when('type', {
          is: (type: string) => {
            return researchConsumerType.map(value => value.id).includes(type)
          },
          then: Yup.string()
              .matches(/^[0-9]{5}$/, 'Il formato del codice postale non è valido.')
              .max(5, 'Il valore massimo consentito è di 5 caratteri')
            .required('Il codice postale è obbligatorio e non può essere vuota.')
        })
      })}
      onSubmit={(values: FormikValues) => {
        if (handleUpdateData) {
          handleUpdateData(values);
        }
      }}
    >
      {({
          handleSubmit,
          values
        }) => (
        <form onSubmit={handleSubmit}>
          <Card sx={{minWidth: 275, mt: 2}}>
            <CardContent>
              <Box mt={2}>
                <RegistrationUserSection readOnly={false}/>
                {researchProducerType.map(value => value.id).includes(values.type) && <RegistrationProducerSection readOnly={false} institutions={institutions}/>}
                {researchConsumerType.map(value => value.id).includes(values.type) && <RegistrationConsumerSection readOnly={false} />}
              </Box>
            </CardContent>
          </Card>
          <RegistrationStepButtons handleNext={handleSubmit} handleBack={handleBack} activeStep={activeStep}/>
        </form>
      )}
    </Formik>
  </React.Fragment>
)

export default RegistrationUserStep;
