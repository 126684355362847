
const unisalentoLabs: Map<string, string[]>  = new Map();

unisalentoLabs.set('BENI CULTURALI', [
    'AEROTOPOGRAFIA E REMOTE SENSING',
    'ANALISI CHIMICHE PER L\'AMBIENTE E I BENI CULTURALI',
    'ANTROPOLOGIA FISICA DELL\'UNIVERSITA\' DEL SALENTO',
    'ARCHEOBOTANICA E PALEOECOLOGIA',
    'ARCHEOLOGIA CLASSICA',
    'ARCHEOLOGIA MEDIEVALE (LAM)',
    'ARCHEZOOLOGIA DELL\'UNIVERSITA\' DEL SALENTO (LAZUS)',
    'GEOGRAFICA ECONOMICO POLITICA',
    'LABORATORIO PER LE EVIDENZE NUMISMATICHE',
    'LABORATORIO PER LE FONTI STORICHE',
    'PALETNOLOGIA E DI ARCHEOMETALLURGIA DELLA PROTOSTORIA',
    'RESTAURO DI MATERIALI ARCHEOLOGICI',
    'SCIENZE APPLICATE ALL\'ARCHEOLOGIA',
    'SPETTROMETRIA DI MASSA ANALITICA E ISOTOPICA',
    'STORIA, RAPPRESENTAZIONE E RILIEVO DELL\'ARCHITETTURA E DEI CENTRI URBANI',
    'STUDI SULLA CERAMICA DEL MONDO ANTICO E MEDIEVALE (CERMAM)',
    'TECNICHE ARTISTICHE E RESTAURO PER LA STORIA DELL\'ARTE',
    'TERRITORIO ARTE E STORIA DELL\'ARTE CONTEMPORANEA',
    'TOPOGRAFIA ANTICA E FOTOGRAMMETRIA (LABTAF)'
]);
unisalentoLabs.set('INGEGNERIA DELL\'INNOVAZIONE', [
    'AVRLAB – AUGMENTED AND VIRTUAL REALITY LABORATORY',
    'CAD/CAM - BRINDISI',
    'CAD/CAM - LECCE',
    'CALCOLO AVANZATO',
    'CAMPI ELETTROMAGNETICI',
    'COMBUSTIONE SPRAY',
    'COR (CONTROL, OPTIMIZATION AND ROBOTIES)',
    'DIDATTICO PER LE MACCHINE A FLUIDO',
    'DIDA LAB',
    'DATA LAB',
    'ELECTROMAGNETIC SOLUTIONS FOR HITECH',
    'ELECTRONIC DESIGN AND RAPID PROTOTIPYING',
    'ELETTROCHIMICA APPLICATA',
    'ELETTRONICA E LABORATORIO DI SENSOR INTERFACE AND ELETTRICAL SYSTEMS',
    'EUMER (EUROPEAN MARITIME & ENVIROMENTAL RESEARCH)',
    'FABLAB',
    'FENOMENI DI TRASPORTO',
    'FISICA TECNICA',
    'GRANDI APPARECCHIATURE',
    'GREEN ENGINE – AEROSPACE PROPULSION AND FLOW DIAGNOSTIC',
    'GREEN ENGINE - COMBUSTIONE',
    'GSA-LAB',
    'HIGH PERFORMANCE COMPUTING',
    'IDA LAB',
    'INGEGNERIA BIOCLIMATICA',
    'INGEGNERIA BIOMEDICA - (SEDE DHITECH)',
    'INGEGNERIA ELETTRICA',
    'INLAB UNISALENTO - (CITTADELLA DELLA RICERCA - BRINDISI)',
    'LABORATORIO LEGGERO DI CPS LAB (CYBER PHISICAL SYSTEMS LABORATORY)',
    'LABORATORIO LEGGERO Centre for Applied Mathematics and Physics for Industry (CAMPI)',
    'LABORATORIO LEGGERO DI CORE (COLLABORATIVE HOLISTIC RESEARCH ENVIROMENT)',
    'LABORATORIO LEGGERO DI CRL (CYBERSECURITY RESEARCH LAB)',
    'LABORATORIO LEGGERO ICT4 INDUSTRY',
    'MACCHINE MOTRICI',
    'MECCANICA SPERIMENTALE',
    'MECCATRONICA E ROBOTICA APPLICATA',
    'METALLURGIA',
    'MICROSCOPIA OTTICA E ELETTRONICA PER SCANSIONE E SPETTROSCOPIA XRD',
    'MISURE',
    'MISURE MECCANICHE E BIOMEDICHE',
    'PRISCO',
    'PROVE MECCANICHE – TISMA',
    'PROVE STRUTTURALI – EMILIA - CASARANO',
    'RETI DI CALCOLATORI',
    'SALENTO RACING TEAM',
    'SCIENZA E TECNOLOGIE DEI MATERIALI',
    'STRUTTURE AEROSPAZIALI',
    'TECHNOLOGIES FOR SUSTAINABLE ENERGY',
    'TECNICA DELLE COSTRUZIONI',
    'TECNOLOGIE CHIMICHE',
    'TELECOMUNICAZIONI',
    'TRASFORMA',
    'URBAN FARMING',
    'VEICOLI PER LA MOBILITA\' SOSTENIBILE',
    'VIBRAZIONI MECCANICHE'
]);
unisalentoLabs.set('MATEMATICA E FISICA', [
    'AEROSOL E CLIMA - ASTROFISICA',
    'ARCHEOMETRIA',
    'ASTROFISICA',
    'ASTROPARTICELLE',
    'CEDAD – OPTOLAB',
    'CENTRO DI CALCOLO',
    'CENTRO DI RICERCA IN CONTATTOLOGIA AVANZATA (CERCA)',
    'CLAMS DEL CEDAD',
    'CLEAN ROOM MATERIALI',
    'DEPOSIZIONE FILM SOTTILI',
    'DIDATTICI DEL CORSO DI LAUREA IN FISICA',
    'DIDATTICI DEL CORSO DI LAUREA IN OTTICA ED OPTOMETRIA',
    'ELECTRON MICROSCOPY LABORATORY',
    'ELETTRONICA',
    'ELETTRONICA APPLICATA E STRUMENTAZIONE',
    'FACILITY DI NANOBIOELETTRONICA, NANOBIOTECNOLOGIE E NANOMEDICINA',
    'FISICA APPLICATA',
    'FISICA APPLICATA ALL\'AMBIENTE E AI BENI CULTURALI',
    'FISICA BIOMEDICA E AMBIENTE',
    'FOTONICA',
    'GRAFITIZZAZIONE E ANALISI ISOTOPICHE',
    'GRANDI APPARATI',
    'HYBRID AND ORGANIC PHOTOVOLTAICS (HOPV)',
    'HYBRID AND ORGANIC PHOTOVOLTAICS (HOPV)',
    'INFORMATICO PER LA DIDATTICA',
    'LASER NANO LAB',
    'MICROSCOPIA ELETTRONICA',
    'OFFICINA MECCANICA',
    'OLED LAB',
    'RICERCA-FACILITY OF NANOFABRICATION',
    'RILEVATORI E GAS',
    'RIVELATORI E STATO SOLIDO',
    'SPINTRONICA E SENSORISTICA - LAB ON CIP - CARATTERIZZAZIONE',
    'TANDETRON DEL CEDAD'
]);
unisalentoLabs.set('MEDICINA SPERIMENTALE', [
    'ANATOMIA UMANA E NEUROSCIENZE',
    'BIOCHIMICA',
    'BIOLOGIA CELLULARE',
    'BIOLOGIA MOLECOLARE',
    'ENDOCRINOLOGIA ED ONCOLOGIA MOLECOLARE DELLA TIROIDE',
    'FISIOLOGIA COMPARATA E FISIOLOGIA GENERALE',
    'IGIENE, MEDICINA PREVENTIVA ED EPIDEMIOLOGIA MOLECOLARE - SETTORE AMBIENTE E ALIMENTARE',
    'IGIENE, MEDICINA PREVENTIVA ED EPIDEMIOLOGIA MOLECOLARE - SETTORE MEDICINA DI COMUNITA\'',
    'IGIENE, MEDICINA PREVENTIVA ED EPIDEMIOLOGIA MOLECOLARE - SETTORE SIEROEPIDEMIOLOGICO',
    'MICROBIOLOGIA GENERALE ED APPLICATA'
]);
unisalentoLabs.set('SCIENZE DELL’ECONOMIA', []);
unisalentoLabs.set('SCIENZE E TECNOLOGIE BIOLOGICHE ED AMBIENTALI', [
    'ANATOMIA COMPARATA E CITOLOGIA',
    'BIOFORIU',
    'BIOLOGIA CELLULARE DEI VEGETALI',
    'BOTANICA SISTEMICA ED ECOLOGIA VEGETALE',
    'CHIMICA ANALITICA',
    'CHIMICA BIO-ORGANICA',
    'CHIMICA FISICA',
    'CHIMICA GENERALE E INORGANICA',
    'CHIMICA ORGANICA SINTETICA',
    'CHIMICA PER L\'AMBIENTE E DEI BENI CULTURALI',
    'CLIMATOLOGIA',
    'COLTIVAZIONI ARBOREE',
    'ECOLOGIA',
    'FISIOLOGIA APPLICATA',
    'FISIOLOGIA CELLULARE',
    'FISIOLOGIA GENERALE E AMBIENTALE',
    'FISIOLOGIA VEGETALE E BIOLOGIA MOLECOLARE DEI VEGETALI',
    'GENETICA',
    'GEOFISICA APPLICATA GEORISORSE E DIAGNOSTICA TERRITORIALE',
    'IGIENE APPLICATA',
    'IMMUNOLOGIA ED ANATOMIA COMPARTA DELLO SVILUPPO',
    'LABORATORI DIDATTICI DISTEBA',
    'MICROBIOLOGIA AGRARIA E TECNOLOGIE ALIMENTARI',
    'MICROMETEOROLOGIA',
    'MOLECOLE BIOATTIVE E MACROMOLECOLE',
    'NANOTECNOLOGIE CHIMICHE',
    'PATOLOGIA VEGETALE',
    'PROCESSI COGNITIVI E PSICOFISIOLOGICI DELL\'OLFATTO',
    'TECNOLOGIE RICOMBINANTI',
    'TRASPORTO E COMPARTIMENTAZIONE PIANTE',
    'ZOOGEOGRAFIA E FAUNA',
    'ZOOLOGIA E BIOLOGIA MARINA',
    'ZOOLOGIA MARINA APPLICATA'
]);
unisalentoLabs.set('SCIENZE GIURIDICHE', []);
unisalentoLabs.set('SCIENZE UMANE E SOCIALI', [
    'FOTOGRAFIA SUBACQUEA E MONITORAGGIO DEI SISTEMI COSTIERI',
    'GEO-CARTOGRAFICO',
]);
unisalentoLabs.set('STUDI UMANISTICI', [
    'CENTRO DI RICERCA INTERDISCIPLINARE SUL LINGUAGGIO (CRIL)',
    'LESSICO ETIMOLOGICO ITALIANO (LEI)',
    'LETTURA, FOTOGRAFIA E RESTAURO DEL PAPIRO'
]);

export default unisalentoLabs;